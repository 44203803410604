<template>
  <div class="page-home">
    
    <div class="bg-theme-sec">
      <div class="text-center" style="height: 170px;">
        <span class="site-logo" style="height:170px;width:170px;"></span>
      </div>

      <div class="text-center b pb-4" style="font-size: 1.1em;">

        <a class="mx-3">
          {{lg('',{
            en: 'More Info',
            zh: '',
          })}}
        </a>

        <a class="mx-3">
          {{lg('',{
            en: 'Genesis Topic',
            zh: '',
          })}}
        </a>

        <!-- <a class="mx-3">
          {{lg('',{
            en: 'Audit Report',
            zh: '',
          })}}
        </a> -->

      </div>
    </div>

    <div class="container">
      <div class="text-center py-4">
        <h5 class="b">
          {{lg('',{
            en: 'What is PEEP?',
            zh: '项目介绍：',
          })}}
        </h5>
        <div class="small">
          
          <div v-if="$store.state.app.lang == 'zh'">
            在NFT的世界里行成了两种范式，一种是类似于Cryptopunk这种自上而下发起的NFT成品，另一种是类似于loot这样的NFT组件，前者缺少拓展性，后者则过分强调拓展，因此今天Axis提出一种新的范式，即部分限定性的NFT拓展方式，我们可以称之为部分可激活NFT---在同主题下鼓励用户或者创作者进行部分属性的创新。在这种情况下，NFT的价值不在取决于电脑分配的随机数，或者主题本身带来的价值，而取决于创作者的水平。在我们看来这可能不仅仅是NFT的发展趋势，还可能是文创的发展趋势，或许你能读很多书很多文章，但是这本书这篇文章仅仅真正意义属于一个人，因为它一开始就生成于区块链之上。
          </div>

          <div v-else>
            PEEP is a new paradigm of creator-centric, community-driven spontaneous innovation.
            <br>
            In the world of NFTs there are two paradigms. One is a top-down NFT product like Cryptopunks. The other are NFT components like LOOT. The former lacks scalability and is still team generated. The latter over-emphasizes extension, so today AXIS proposes a new paradigm.
            <br>
            That is, a community-driven partial limited NFT extension, partial NFT activation - under the same theme, yet limited in quantity, encouraging users or creators to make some attribute innovation. In this case, the value of the NFT depends not on the random number assigned by the computer, or the value the subject itself brings, but on the level of the creator. In our view, this may not only be the trend for NFTs, but It may also be reflective of the zeitgeist’s trend of cultural and creative development expression. Maybe you can read a lot of books and articles, but this book… this article only belongs to one person, because it was generated on the blockchain in the beginning. 
            <br>
          </div>
        </div>

        <hr class="my-4">

        <h5 class="b">
          {{lg('',{
            en: 'PEEP Series #1: #Satoshi ',
            zh: 'PEEP 系列 #1：#Satoshi',
          })}}
        </h5>
        <div class="small d-flex justify-content-center">
          <div v-if="$store.state.app.lang == 'zh'" class="text-left">
            对于 PEEP Genesis 系列，第一个主题将是 #SATOSHI。以下是您需要了解的内容：
            <br>
            • 为致敬中本聪 2100 万 BTC 最大供应量的精神，NFT 总数 = 2100。
            <br>
            • 一个钱包地址只能领取一个代币/NFT。
            <br>
            • 先到先得；当CLAIM结束时，Satoshi也随之结束。
            <br>
            • Satoshi在以太链上；CLAIM 只需要 支付GAS FEE。
            <br>
            • 代币有序列号，从0001-2100；序列号是按照铸造的顺序进行的。
            <br>
            • 用户可以像使用 TWITTER 一样编写文本，并且不能超过 210 个字符的限制。
          </div>
          <div v-else>
            For the PEEP Genesis Series, the first topic will be about #SATOSHI. Here is what you need to know:
            <br>
            ● Total number of NFTs = 2100 in the spirit of Satoshi’s 21M BTC max supply. 
            <br>
            ● One wallet address can only claim one token/NFT. 
            <br>
            ● First come, first serve. When the claim is over, there will be no more. 
            <br>
            ● It is on the ETHER chain. CLAIM only requires GAS FEE. 
            <br>
            ● There are serial numbers, from 0001-2100; the serial numbers are cast in the order of who claims. 
            <br>
            ● Users can write text, like using TWITTER, and cannot exceed the 210-character limit.
            <br>
            ● The theme of the first issue is #SATOSHI. 
          </div>
        </div>

      </div>
    </div>

    <div class="container pb-3">


      <div class="ui-card px-5 py-4">
        <textarea 
          ref="el_input_text"
          type="text" 
          class="w-100"
          style="border: none; background-color: transparent;font-size: 1.2em;min-height: 7.8em;line-height: 1.5em; width: 100%;"
          placeholder="Mint your own NFT re. #Satoshi"
          v-model="input_text"
          maxlength="210"
          @focus="placeCursor()"
          >
        </textarea>
        <div class="mt-1 text-right">
          <span 
            class="btn btn-primary rounded" 
            style="min-width: 170px;"
            @click="actionHappyMint()"
            >
            {{lg('',{
              en: 'Mint',
              zh: '',
            })}}
          </span>
        </div>
      </div>
    </div>

    <UiModal :open="status_show_mint_err" @close="status_show_mint_err = false">
      <div class="px-4 pt-4 pb-3">
        {{lg('',{
          en: 'One wallet address can only claim one NFT. This wallet has claimed already. ',
          zh: '一个钱包地址只能领取一个代币/NFT。这个钱包已经领取。',
        })}}

        <div class="mt-4 text-center">
          <span class="btn btn-primary" style="width:100px;" @click="status_show_mint_err = false">
            Ok
          </span>
        </div>
      </div>
    </UiModal>

    <div class="mt-4 container">
      <div v-if="max_id" class="sp-list-main">
        <div 
          class="sp-item"
          v-for="item of (max_id - 1)"
          :key="item"
          >
          <spItem
            :data="{
              id: item,
            }"
          />
        </div>
      </div>
    </div>

    <div class="mb-5">
      <!--  -->
    </div>

  </div>
</template>

<script>
import {mapActions} from 'vuex'
import spItem from './sp_item.vue'


export default {
  name: 'home',
  components: {
    spItem,
  },
  props: [],
  data() {
    return {
      input_text: '',
      status_show_mint_err: false,

      max_id: '',
    }
  },
  computed: {

  },
  watch: {},
  methods: {
    ...mapActions([
      'happyMint',
      'getMaxID',
    ]),

    placeCursor(){
      let v = this
      let el = v.$refs['el_input_text']

      let index = v.input_text.indexOf(' #Satoshi')

      if(el.setSelectionRange){
        setTimeout(function(){
          el.setSelectionRange(index,index)
        },50)
      }
    },

    popupMintErr(){
      let v = this
      v.status_show_mint_err = true
    },

    actionHappyMint(){
      let v = this

      if(!v.$store.state.web3.account){
        return
      }

      v.happyMint({
        address_my: v.$store.state.web3.account,
        text: v.input_text,
        callbackOnErr: function(){
          v.popupMintErr()
        },
      })
    },

    async run(){
      let v = this

      // 
      v.max_id = await v.getMaxID() | ''
      v.input_text = ' #Satoshi' + v.max_id

      // 
      
    },
  },
  created() {
    this.run()
    return
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  .page-home{

    .sp-list-main{
      display: flex;
      flex-wrap: wrap;
      margin-left: -2.8%;


      .sp-item{
        margin-left: 2.8%;
        margin-bottom: 20px;
        &:empty{
          display: none;
        }
      }

      // mobile
      @media(max-width: 788px){
        .sp-item{
          // width: 100%;
          width: 50%;
        }
      }

      // pc
      @media(min-width: 788px){
        .sp-item{
          width: 30.5%;
        }
      }
    }
  }
</style>

// 全局变量

let local_lang = 'en'
// let lang_arr = ['zh']
// for(let item of lang_arr){
//   if(window.navigator.language.indexOf(item) === 0){
//     local_lang = item
//     break
//   }
// }
if(window.localStorage.lang && ['zh','en'].indexOf(window.localStorage.lang)>=0){
  local_lang = window.localStorage.lang
}

let cfg = {
  dev: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://ropsten.etherscan.io/address/",
    url_transaction_view: "https://ropsten.etherscan.io/tx/",
    chain_id: 3
  },

  rinkeby: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://rinkeby.etherscan.io/address/",
    url_transaction_view: "https://rinkeby.etherscan.io/tx/",
    chain_id: 4
  },

  kovan: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://kovan.etherscan.io/address/",
    url_transaction_view: "https://kovan.etherscan.io/tx/",
    chain_id: 42
  },

  bsc: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://bscscan.com/",
    url_transaction_view: "hhttps://bscscan.com/tx/",
    chain_id: 56
  },
  bsc97: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://testnet.bscscan.com/",
    url_transaction_view: "https://testnet.bscscan.com/tx/",
    chain_id: 97
  },

  prod: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://etherscan.io/address/",
    url_transaction_view: "https://etherscan.io/tx/",
    chain_id: 1
  },

  heco: {
    default_lang: local_lang, // en zh id
    url_network_address_view: "https://hecoinfo.com/address/",
    url_transaction_view: "https://hecoinfo.com/tx/",
    chain_id: 128
  },
  
};

// 输出
export default cfg.prod

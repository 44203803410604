import { mapState } from 'vuex';
import numeral from 'numeral';
import prettyMs from 'pretty-ms';
import store from '@/store';
import config from '@/helpers/config';
import { shorten, etherscanLink } from '@/helpers/utils';

// @ts-ignore
const modules = Object.entries(store.state).map(module => module[0]);

import asset_list from '@/data/asset_list.js'

export default {
  data() {
    return {
      config
    };
  },
  computed: {
    ...mapState(modules)
  },
  methods: {

    async refreshData(){
      console.log('refreshData')
      
      // 清控老数据
      store.commit('setDataKey', {
        asset_deposit_list: [],
        my_deposit_and_borrow_list: [],
        my_dashboard: {},
      })

      if ((store as any).state.data.asset_list.length == 0) {
        await store.dispatch('getMarketAll')
      }

      // 获取新数据
      await store.dispatch('getMyDashboard')
      await store.dispatch('getMarketBalanceAll')
      await store.dispatch('getMyDepositAndBorrowInfoAll')
    },
    
    // 传参如 0.5 DAI
    calcValueOnUsd(num,token_id){
      let id = asset_list.find(i => i.id === token_id).query_id_for_exchange_price

      let price_usd = ((store as any).state.data.price_on_usd[id] || {}).usd || 0
      return num * price_usd
    },
    // 传参如： 0.5 DAI ETH（09.5个DAI值多少ETH）
    calcValueOnToken(num_token_a, token_id_a, token_id_b) {

      let id_a = asset_list.find(i => i.id === token_id_a).query_id_for_exchange_price
      let id_b = asset_list.find(i => i.id === token_id_b).query_id_for_exchange_price

      let price_a_on_usd = ((store as any).state.data.price_on_usd[id_a] || {}).usd || 0
      let price_b_on_usd = ((store as any).state.data.price_on_usd[id_b] || {}).usd || 0

      return num_token_a * price_a_on_usd / price_b_on_usd
    },

    _ms(number) {
      const diff = number * 1e3 - new Date().getTime();
      return prettyMs(diff);
    },
    _numeral(number, format = '(0.[00]a)') {
      return numeral(number).format(format);
    },
    _shorten(str: string, key: string): string {
      let limit;
      if (key === 'symbol') limit = 6;
      if (key === 'name') limit = 64;
      if (key === 'choice') limit = 12;
      if (limit)
        return str.length > limit ? `${str.slice(0, limit).trim()}...` : str;
      return shorten(str);
    },
    _etherscanLink(str: string, type: string): string {
      return etherscanLink(str, type);
    }
  }
};

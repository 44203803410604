<template>
  <div @click.stop class="the-mb-nav bg-theme-sec pb-4 pt-2 px-4 tar anim-slide-in">
    <!-- <div class="mb-3 pc-none">
      <TopnavMainnav/>
    </div> -->

    <!-- <div class="mb-3 pc-none" >
      <TopnavAccountBtn/>
    </div> -->

    <div class="mb-3">
      <HeaderLangSelect class="ml-auto"/>
    </div>

    <!-- <div>
      <TopnavThemeBtn class="ml-auto"/>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'mbNav',
  components: {},
  props: [],
  data() {
    
    return {

    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created() {
    return
  },
  mounted() {
    
  },
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  .the-mb-nav{
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 9;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
</style>
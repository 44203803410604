<template>
  <div class="topnav-box py-2 bg-theme-sec">
    <nav id="topnav" class="topnav mx-auto" style="max-width: 1200px;position: relative;">
      <div class="px-3">
        <div class="d-flex align-items-center flex-wrap">

          <!-- <router-link
            to="/"
            class="d-inline-block usn tac"
            >

            <span class="site-logo" style="width: 150px; height: 60px;">
              
            </span>

          </router-link> -->

          <!-- nav -->
          <!-- <div class="mb-none ml-auto mr-5">
            <TopnavMainnav class="flex-auto d-flex align-items-center flex-wrap mb-jcc"/>
          </div> -->
          
          <!-- account btn -->
          <div class="mb-none_xx mr-3 ml-auto">
            <TopnavAccountBtn/>
          </div>

          <!-- <div class="mb-none">
            <HeaderLangSelect class="ml-2"/>
          </div> -->
          
          <div class="p-2 mb-ml-auto cp" @click.stop="toggleDropNav()">
            <i class="fas fa-bars" style="color: #878BB2;"></i>
          </div>

          <div v-if="dropnavOpen">
            <TopnavDropNav/>
          </div>
        </div>

        <!-- <ModalAccount
          :open="modalOpen"
          @close="modalOpen = false"
          @login="handleLogin"
        />
        <ModalAbout :open="modalAboutOpen" @close="modalAboutOpen = false" /> -->

      </div>
    </nav>
  </div>
</template>

<script>
import bus from '@/bus.js'
import { mapActions } from 'vuex';


export default {
  data() {
    return {
      
      modalOpen: false,
      // modalAboutOpen: false,

      dropnavOpen: false,
      // dropnavOpen: (new Date()).valueOf() -  v.$store.state.app.mark_st_lang_changed <= 1000? true: false,
    };
  },
  computed: {
    
  },
  methods: {
    ...mapActions(['login']),

    toggleDropNav(val){
      
      let v = this
      let mark = !v.dropnavOpen

      if(val !== undefined){
        mark = val
      }
      
      v.dropnavOpen = mark
    },

  },
  created(){
    let v = this

    bus.topnavvue = this

    // autologin
    if(!v.$store.state.web3.account && window.localStorage.markAutoLogin == 1){
      this.login()
    }


  },
  mounted(){
    document.body.addEventListener('click',()=>{
      this.dropnavOpen = false;
    },false);
  },
};
</script>

<style lang="scss" scope>
  @import '@/assets/css/vars.scss';

  .topnav-box{
    // background-color: #fff;

    .topnav{
      .main-nav{
        a{
          line-height: 40px;
          font-size: 14px;

          &.router-link-exact-active{
            color: $c !important;
          }
        }
      }
      @media(max-width: 789px){
        .main-nav{
          margin: 10px 0;
        }
      }
    }
  }
  // hr.colored{
  //   margin: 0;
  //   border: none;
  //   width: 100%;
  //   height: 1px;
  //   opacity: 0.5;
  //   background-color: $c_border;
  //   margin-top: 30px;
  // }
</style>

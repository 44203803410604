<template>
  <div>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'storeWatch',
  components: {},
  props: [],
  data() {
    return {
      
    }
  },
  computed: {},
  watch: {
    async '$store.state.web3.account'(val){

      console.log('account changed.')

      if(val){

        // app reload
        this.$parent.reloadApp()
      }
    },
  },
  methods: {
    ...mapActions([
    ]),
  },
  created() {
    
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  
</style>
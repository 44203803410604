import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import home from "@/views/home/index.vue"
// import my_dashboard from "@/views/my_dashboard/index.vue"
// import deposit from '@/views/deposit/index.vue'
// import deposit_item from '@/views/deposit_item/index.vue'
// import repay_item from '@/views/repay_item/index.vue'
// import withdraw_item from '@/views/withdraw_item/index.vue'
// import borrow_item from '@/views/borrow_item/index.vue'
// import borrow from '@/views/borrow/index.vue'
// import reserve_overview from '@/views/reserve_overview/index.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: home
  },

  // {
  //   path: "/my_dashboard",
  //   name: "my_dashboard",
  //   component: my_dashboard
  // },

  // {
  //   path: "/deposit",
  //   name: "deposit",
  //   component: deposit
  // },
  
  // {
  //   path: "/deposit/:id",
  //   name: "deposit_item",
  //   component: deposit_item
  // },

  // {
  //   path: "/repay/:id",
  //   name: "repay_item",
  //   component: repay_item
  // },

  // {
  //   path: "/withdraw/:id",
  //   name: "withdraw_item",
  //   component: withdraw_item
  // },

  // {
  //   path: "/borrow",
  //   name: "borrow",
  //   component: borrow
  // },

  // {
  //   path: "/borrow/:id",
  //   name: "borrow_item",
  //   component: borrow_item
  // },

  // {
  //   path: "/reserve_overview/:id",
  //   name: "reserve_overview",
  //   component: reserve_overview
  // },

  {
    path: "/*",
    name: "error-404",
    beforeEnter: (to, from, next) => next("/")
  }
];

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
});

export default router;

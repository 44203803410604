<template>
  <div class="px-4 py-3 border-top d-block">
    <div
      class="bg-gray-9 rounded-1 anim-pulse mb-2"
      style="width: 60%; height: 28px;"
    />
    <div
      class="bg-gray-9 rounded-1 anim-pulse"
      style="width: 50%; height: 28px;"
    />
  </div>
</template>

<script>
export default {
  props: {
    token: String,
    proposal: Object,
    i: Number
  }
};
</script>

<template>
  <div class="main-nav">

    <router-link
      :to="'/'"
      @click.native="hideMainnav()"
      >
      {{lg('',{
        en: 'Home',
        zh: '',
      })}}
    </router-link>

    <router-link
      :to="{name: 'xxx'}"
      @click.native="hideMainnav()"
      >
      {{lg('',{
        en: 'Menu2',
        zh: '',
      })}}
    </router-link>

    <router-link
      :to="{name: 'xxx'}"
      @click.native="hideMainnav()"
      >
      {{lg('',{
        en: 'Menu2',
        zh: '',
      })}}
    </router-link>

    <router-link
      :to="{name: 'xxx'}"
      @click.native="hideMainnav()"
      >
      {{lg('',{
        en: 'Menu3',
        zh: '',
      })}}
    </router-link>

    <!-- <a>
      Contact
    </a> -->

    <!-- <a 
      :href="`https://info.xxx.com`" 
      target="_blank" 
      class="d-block ml-5" 
      @click="hideMainnav()">
      {{lg('',{
        en: 'xxx',
        zh: '外部链接',
      })}}
    </a> -->

  </div>
</template>

<script>
import bus from '@/bus.js'

export default {
  name: 'mainnav',
  components: {},
  props: [],
  data() {
    return {
      
    }
  },
  computed: {},
  watch: {},
  methods: {
    hideMainnav(){
      console.log(bus.topnavvue)
      bus.topnavvue.dropnavOpen = false
    },
  },
  created() {
    return
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  .main-nav{
    a{
      margin-left: 40px;;
    }
  }
  @media(max-width: 789px){
    .main-nav{
      a{
        display: block;
      }
    }
  }
</style>
<template>
  <div class="sp-block ui-card px-5 text-center" style="padding-top: 2em; padding-bottom: 2em;">
    <div class="txt-box d-flex justify-content-center align-items-center p-3">
      
      <img 
        v-if="data_obj"
        :src="data_obj.image"
      >

    </div>
    <div class="mt-3">
      <a
        :href="$store.state.app.peep_item_buy_base_url + data.id"
        target="_blank"
        class="btn btn-primary rounded w-100"
        style=""
        >
        {{lg('',{
          en: 'Buy',
          zh: '',
        })}}
      </a>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'spItem',
  components: {},
  props: [
    'data',
  ],
  data() {
    return {
      data_obj: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions([
      'getTokenURI',
    ]),

    async run(){
      let v = this

      // img
      let res_img = await v.getTokenURI({
        tokenID: v.data.id,
      })
      
      try{
        v.data_obj = JSON.parse( atob( res_img.replace('data:application/json;base64,','') ))
      }
      catch{
        console.log('json parse err')
      }
      // 
    },
  },

  created() {
    this.run()
    return
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  .sp-block{

    .txt-box{
      border: 3px solid #333;
      border-radius: 20px;
      background: #fff;
      min-height: 200px;
    }
  }
</style>
<template>
  <div class="site-footer py-3 bg-theme-sec text-center d-flex justify-content-center align-items-center">

    <span>
      Powered by
    </span>
    <img src="/static/img/footer_axis.png" class="mx-1" style="width: 25px;">
    <b class="u">
      <a href="https://axisdefi.com/" target="_blank">
        AXIS
      </a>
    </b>
  </div>
</template>

<script>

export default {
  name: 'siteFooter',
  components: {},
  props: [],
  data() {
    return {
      status_open_wechat: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created() {
    
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/vars.scss';
  
  .site-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
import sitecfg from './sitecfg.js'

let json = {
  env: "master",
  network: "homestead",
  chainId: sitecfg.chain_id,
  connectors: {
    injected: {
      id: "injected",
      name: "MetaMask"
    },
    // binancechain: {
    //   id: "binancechain",
    //   name: "BinanceChain"
    // },
    // fortmatic: {
    //   id: "fortmatic",
    //   name: "Fortmatic",
    //   options: {
    //     apiKey: "pk_live_9CE8FD92E54684ED"
    //   }
    // },
    // portis: {
    //   id: "portis",
    //   name: "Portis",
    //   options: {
    //     dappId: "3f1c3cfc-7dd5-4e8a-aa03-71ff7396d9fe",
    //     network: "mainnet"
    //   }
    // },
    // walletconnect: {
    //   id: "walletconnect",
    //   name: "WalletConnect",
    //   options: {
    //     rpc: {
    //       "128":
    //         "https://http-mainnet-node.huobichain.com"
    //     },
    //     chainId:128
    //   }
    // },
    // walletlink: {
    //   id: "walletlink",
    //   name: "Coinbase",
    //   options: {
    //     appName: "Snapshot",
    //     darkMode: false,
    //     chainId: 1,
    //     ethJsonrpcUrl:
    //       "https://eth-mainnet.alchemyapi.io/v2/rXKbp7PTFm6hcrxU8NL-JGp5RMfRHDwg"
    //   }
    // }
  }
};

export default json;

// 字段说明：
// ui_icon_url：显示token icon的
// query_id_for_exchange_price 用来查询汇率的key, 如： https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd

export default [
    {
        id:'ETH',
        token_address:'0xd0A1E359811322d97991E03f863a0C30C2cF029C',
        token_decimals:18,
        atoken_address: '0xBc54CcfAc6d17d558E41FCAA04a05Da152C7ba39',
        atoken_decimals:18,
        atoken_stable_address: '0x1Aed43E0F475925fd328E393385b866D9EF43E3a',
        atoken_stable_decimals:18,
        atoken_variable_address: '0xb73C5229f7ACD2E37eCBcaEE0384dC36c2DEdf26',
        atoken_variable_decimals:18,
        ui_icon_url: "/static/pic/token_img/ETH.svg",
        query_id_for_exchange_price: "ethereum",
    },

    {
        id:'DAI',
        token_address:'0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD',
        token_decimals:18,
        atoken_address: '0xd48241133D8AeE35B08d0860Cef50Eed6eaeEc1B',
        atoken_decimals:18,
        atoken_stable_address: '0x2824ca17e82f1973AB4bdF57c2EB13Cbaf979067',
        atoken_stable_decimals:18,
        atoken_variable_address: '0x5C69222D6674CB1bF5935Bd33c54417b911CF3fa',
        atoken_variable_decimals:18,
        // ↓ other info
        ui_icon_url: "/static/pic/token_img/DAI.svg",
        query_id_for_exchange_price: "dai",
    },

    {
        id:'USDT',
        token_address:'0x13512979ADE267AB5100878E2e0f485B568328a4',
        token_decimals:6,
        atoken_address: '0x04AfA1200cb3d02513cc9C4c8368a6c9bdB8Ae5e',
        atoken_decimals:6,
        atoken_stable_address: '0x8bb0A9d83f977290d4E78eACF744Ef1653Ccfceb',
        atoken_stable_decimals:6,
        atoken_variable_address: '0x13028cc58708f165FdBAB370a9F8872e3f6C6486',
        atoken_variable_decimals:6,
        // ↓ other info
        ui_icon_url: "/static/pic/token_img/USDT.svg",
        query_id_for_exchange_price: "tether",
    },

    {
        id:'WBTC',
        token_address:'0xD1B98B6607330172f1D991521145A22BCe793277',
        token_decimals:8,
        atoken_address: '0xc9fa72582Eb30D8264f82850F9d6747fBd9154b5',
        atoken_decimals:8,
        atoken_stable_address: '0xEFe95D8ABc67BAFECA1c4660D47A918D1FDd1820',
        atoken_stable_decimals:8,
        atoken_variable_address: '0x930b49970fA36F00C54E0e9BC0002A35674bFC6d',
        atoken_variable_decimals:8,
        // ↓ other info
        ui_icon_url: "/static/pic/token_img/WBTC.svg",
        query_id_for_exchange_price: "bitcoin",
    },
];

<template>
  <div :key="web3.account" class="account-box pc-ml-auto mb-ml-auto mb-mr-auto">
    <template v-if="$auth.isAuthenticated && !wrongNetwork">
      <button
        @click="modalOpen = true"
        :loading="loading"
        class="ui-btn size-sm outlined"
      >
        <Avatar :address="web3.account" size="16" class="mb-none mr-2 ml-n1" />
        <span v-if="web3.name" v-text="web3.name" />
        <span v-else>
          {{web3.account | shortAddress}}
        </span>
      </button>
    </template>

    <button
      v-if="web3.injectedLoaded && wrongNetwork"
      class="text-red ui-btn size-sm outlined"
      @click="modalOpen = true"
    >
      <Icon name="warning" class="ml-n1 mr-1 v-align-middle" />
      {{lg('',{
        en: 'Wrong network',
        zh: '网络错误',
      })}}
    </button>

    <button
      class="btn btn-primary btn-sm size-sm"
      v-if="showLogin"
      @click="modalOpen = true"
      :loading="loading"
    >
      {{lg('',{
        en: 'Connect wallet',
        zh: '连接钱包',
      })}}
    </button>


    <ModalAccount
      style="z-index: 50;"
      :open="modalOpen"
      @close="modalOpen = false"
      @login="handleLogin"
    />
    
  </div>
</template>

<script>
import bus from '@/bus.js'
import { mapActions } from 'vuex';

export default {
  name: 'accountBtn',
  components: {},
  props: [],
  data() {
    return {

      // 按钮loading效果
      loading: false,
      
      modalOpen: false,
    }
  },
  computed: {
    showLogin() {
      return (
        (!this.$auth.isAuthenticated && !this.web3.injectedLoaded) ||
        (!this.$auth.isAuthenticated && !this.wrongNetwork)
      );
    },
    wrongNetwork() {
      // console.log('======wrongNetwork======')
      // console.log(this.config.chainId,this.web3.injectedChainId)
      return this.config.chainId !== this.web3.injectedChainId;
    },
  },
  watch: {},
  methods: {
    ...mapActions(['login']),

    async handleLogin(connector) {
      this.modalOpen = false;
      this.loading = true;
      await this.login(connector);
      this.loading = false;
      
      // mark
      window.localStorage.markAutoLogin = '1'
    },
  },
  created() {
    bus.topnavAccountBtnVue = this
    return
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  
</style>

import sitecfg from '@/sitecfg.js'

const data_bus = {
  state: {

    // 
    
  },
  mutations: {
    setDataBusKey(state,obj){
      for (let key in obj) {
        state[key] = obj[key];
      }
    },
  },
}

export default data_bus
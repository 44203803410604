
import sitecfg from '@/sitecfg.js'

const app = {
  state: {

    // light dark
    theme: 'dark', 

    // lang
    mark_st_lang_changed: 0,
    lang: sitecfg.default_lang || "en",
    // is_tronlink_installed: window.tronWeb ? true : false,

    // 
    peep_item_buy_base_url: 'https://opensea.io/assets/0x223e06a0715c90ab773576b8ce876c6c564b1ab3/',
  },
  mutations: {
    lang(state, str) {
      state.mark_st_lang_changed = (new Date()).valueOf()
      window.localStorage.setItem('lang',str)
      state.lang = str;
    },
    
    theme(state, str) {
      window.localStorage.setItem('theme',str)
      state.theme = str;
    },
    setAppKey(state, obj) {
      for (let key in obj) {
        state[key] = obj[key];
      }
    },
  },
}

export default app
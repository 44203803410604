<template>

  <!-- select 型 -->
  <div class="lang-selector">
    <span 
      v-for="(item,key) of options" 
      :key="key"
      :class="['mb-2 py-2 cp usn ml-2',{'c':$store.state.app.lang == key}]"
      @click="setAppLang(key)"
    >{{item}}</span>
  </div>

  <!-- switch 开关型 -->
  <!-- <span  
    @click="toggleAppLang()" 
    :class="['btn-switch',{'on-right': lang_type == 'zh'}]"
    style="width: 60px;"
    >
    <span>
      中文
    </span>
    <span>
      En
    </span>
    <span 
      :class="['switch-thumb',`bg-${lang_type}`]"></span>
  </span> -->
  

</template>

<script>
// 组件说明，根据传入的 type ，可以有不同的输出展示
// type 为 clicklist 时，时个列表。默认则为 select 框。


export default {
  name: 'langSelect',
  props: [
    'type',
  ],
  data(){
    return{
      status_poppad_open: false,

      lang_type: '',

      options:{
        zh: '简',
        // zh_t: '繁',
        en: 'EN'
      },
    }
  },
  methods:{
    toggleAppLang(){
      let v = this

      let lang_now = v.$store.state.app.lang
      let lang = 'en'
      if(lang_now == 'zh'){
        lang = 'en'
      }else if(lang_now == 'en'){
        lang = 'zh'
      }
      this.lang_type = lang

      setTimeout(() => {
        v.setAppLang(lang)
      }, 500);
    },
    setAppLang(str){
      let v = this
      if(str){
        v.lang_type = str
      }
      window.lang = v.lang_type
      
      v.$store.commit('lang',v.lang_type)
    },
  },
  created(){
    this.lang_type = this.$store.state.app.lang
  },
}
</script>

<style lang="scss" scoped> 
  .lang-selector{
    color: #bbb;
    .c{
      text-decoration: underline;
    }
  }
</style>

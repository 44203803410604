<template>
  <div 
    id="app" 
    :key="app_key" 
    v-if="app_on_show"
    :class="['overflow-hidden',`theme-${$store.state.app.theme}`]"
    >
    <UiLoading v-if="0 && (ui.loading || !ui.init)" class="overlay big" />
    <div class="frame-wrap" v-else>
      <Topnav />
      <div class="pb-6 overflow-hidden">
        <router-view :key="$route.path" class="flex-auto" />
      </div>

      <SiteFooter/>
    </div>
    <Notifications/>

    <!-- 专门监听 store 的组件 -->
    <storeWatch/>
  </div>
</template>

<script>
import bus from '@/bus.js'

import { mapActions } from 'vuex';
//import spaces from '@/spaces';
import storeWatch from './views/storeWatch.vue'
import asset_list from '@/data/asset_list.js'

export default {
  name: 'app',
  components: {
    storeWatch
  },
  data(){
    return {
      app_key: 0,
      app_on_show: true,
    }
  },
  computed: {
    wrongNetwork() {
      return this.config.chainId !== this.web3.injectedChainId;
    },
    showLogin() {
      return (
        (!this.web3.account && !this.web3.injectedLoaded) ||
        (!this.web3.account && !this.wrongNetwork)
      );
    },
    /*
    space() {
      try {
        return spaces[this.$route.params.key];
      } catch (e) {
        return {};
      }
    }
    */
  },
  watch: {
    '$store.state.app.lang'(){
      let v = this
      v.app_key += 1
    },
  },
  methods: {
    ...mapActions([
      'init',
    ]),

    reloadApp(){
      // this.app_key += 1
      console.log('$forceUpdate')
      this.$forceUpdate()
    },

    async listExchangePrice(){
      let v = this
      // https://api.coingecko.com/api/v3/simple/price?ids=dai&vs_currencies=usd

      let keys = []
      asset_list.forEach( function(item){
        let key = item.query_id_for_exchange_price
        if(key){
          keys.push(key)
        }
      })

      let url = `https://api.coingecko.com/api/v3/simple/price?ids=${keys.join(',')}&vs_currencies=usd`
      let res = await fetch(url)
      res = await res.json()

      v.$store.commit('setDataKey',{
        'price_on_usd': res,
      }) 
    },

    run(){
      let v = this

      // init lang
      let str_lang = 'en' || window.localStorage.getItem('lang')
      if(['zh','en'].indexOf(str_lang) >= 0 && v.$store.state.app.lang != str_lang){
        v.$store.commit('lang',str_lang)
      }
      // init theme
      let str_theme = 'light' ||  window.localStorage.getItem('theme')
      if(['light','dark'].indexOf(str_theme) >= 0 && v.$store.state.app.theme != str_theme){
        v.$store.commit('theme',str_theme)
      }

      // 汇率
      // v.listExchangePrice()
    },
  },
  created(){
    // 
    bus.appvue = this
  },
  mounted() {
    this.init();
    this.run()
    window._bus = bus
  },
};
</script>
<style lang="scss">
  @import '~@/assets/css/style.scss';

  .frame-wrap{
    min-height: 100vh;

    padding-bottom: 70px;
  }
</style>

<template>
  <span  
    @click="toggleTheme()" 
    :class="['btn-switch',{'on-right': $store.state.app.theme == 'light'}]"
    >
    <span>
      Light
    </span>
    <span>
      Dark
    </span>
    <span class="switch-thumb"></span>
  </span>
</template>

<script>
import bus from '@/bus.js'

export default {
  name: '',
  components: {},
  props: [],
  data() {
    return {
      
    }
  },
  computed: {},
  watch: {},
  methods: {
    toggleTheme(){
      let v = this

      let old_theme = v.$store.state.app.theme
      let new_theme = old_theme
      if(old_theme === 'light'){
        new_theme = 'dark'
      }else{
        new_theme = 'light'
      }
      
      v.$store.commit('theme',new_theme)

      setTimeout(() => {
        bus.topnavvue.dropnavOpen = false
      }, 800);
    },
  },
  created() {
    return
  },
  //mounted() {},
  //beforeDestroy() {},
  //update() {},
  //activited() {},
  //beforeRouteUpdate() {},
  //filters: {},
};
</script>

<style lang="scss" scoped>
  
</style>
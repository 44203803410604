
export default {
  // 公共标语
  web_risk_tip: {
    en: "This project is in beta. Use at your own risk.",
    zh: "该项目为beta版本，请考虑风险",
    id: "Proyek ini versi beta, harap pertimbangkan risikonya",
    ko: "본 프로젝트는 베타 버전입니다. 위험에 대해 스스로 감수해야합니다."
  },
  web_logan: {
    en: "Your Yield Farming Optimizer For JustSwap",
    zh: "一键defi挖矿聚合协议",
    id: "Protokol agregasi defi penambangan sekali klik",
    ko: "원클릭 Defi 채굴 프로토콜"
  },
  tip_download_tronlink: {
    en: "New to Tron?  Get the TronLink extension",
    zh: "Tron 网络新用户？请先下载TronLink",
    id: "Baru mengenal jaringan Tron? Silakan unduh TronLink terlebih dahulu",
    ko: "Tron이 처음이신가요? TronLink 확장 프로그램을 받으세요"
  },

  // home页面
  burn: {
    en: "Burn",
    zh: "销毁记录"
  },
  burn_records: {
    en: "Burn Records",
    zh: "管理权限销毁记录"
  },
  connect_wallet: {
    en: "Connect wallet",
    zh: "连接钱包",
    id: "Hubungkan dompet",
    ko: "지갑 연결"
  },
  connect: {
    en: "Connect",
    zh: "连接",
    id: "koneksi",
    ko: "연결"
  },
  wallet: {
    en: "Wallet",
    zh: "钱包",
    id: "dompet",
    ko: "지갑"
  },
  contract_address: {
    en: "Contract Address",
    zh: "合约地址",
    id: "Alamat kontrak",
    ko: "컨트랙트 주소"
  },
  address: {
    en: "Address",
    zh: "地址",
    id: "alamat",
    ko: "주소"
  },
  deposit: {
    en: "Deposit",
    zh: "存入",
    id: "Menyetorkan",
    ko: "예금"
  },
  earn: {
    en: "Earn",
    zh: "赚取",
    id: "Hasilkan",
    ko: "벌다"
  },
  open: {
    en: "Open",
    zh: "打开",
    id: "nyalakan",
    ko: "열기"
  },
  select: {
    en: "Select",
    zh: "选择"
  },
  cancel: {
    en: "Cancel",
    zh: "取消",
    id: "membatalkan",
    ko: "취소"
  },
  tutorial: {
    en: "Tutorial",
    zh: "教程",
    id: "Tutorial",
    ko: "튜토리얼"
  },
  connected: {
    en: "Connected",
    zh: "已链接",
    id: "Ditautkan",
    ko: "연결됨"
  },
  network: {
    en: "Network",
    zh: "网络",
    id: "Network",
    ko: "회로망"
  },
  total_lockup: {
    en: "Total Value Locked",
    zh: "总锁仓量",
    id: "Penguncian total",
    ko: "전체 락업 가치"
  },
  total_APY: {
    en: "APY",
    zh: "年化",
    id: "APY",
    ko: "APY"
  },
  price: {
    en: "Price",
    zh: "价格",
    id: "Harga",
    ko: "가격"
  },
  // 通用app操作
  back: {
    en: "Back",
    zh: "返回",
    id: "kembali",
    ko: "Back"
  },

  // stake 页面
  your_balance: {
    en: "Your Balance",
    zh: "您的余额",
    id: "Keseimbanganmu",
    ko: "자산"
  },
  balance: {
    en: "Balance",
    zh: "余额",
    id: "Keseimbangan",
    ko: "자산"
  },
  currently_staked: {
    en: "Currently Staked",
    zh: "当前抵押",
    id: "Hipotek saat ini",
    ko: "현재 스테이킹"
  },
  rewards_available: {
    en: "Rewards Available",
    zh: "当前奖励",
    id: "Hadiah saat ini",
    ko: "현재 보상"
  },
  // stake 页面 按钮
  approve: {
    en: "Approve",
    zh: ""
  },
  stake: {
    en: "Stake",
    zh: "抵押",
    id: "hak Tanggungan",
    ko: "스테이킹"
  },
  stake_tokens: {
    en: "Stake Tokens",
    zh: "抵押代币",
    id: "Token hipotek",
    ko: "토큰 스테이킹"
  },
  unstake: {
    en: "Unstake",
    zh: "取消抵押",
    id: "Batalkan hipotek",
    ko: "모기지 취소"
  },
  unstake_tokens: {
    en: "Unstake Tokens",
    zh: "取消抵押",
    id: "Batalkan hipotek",
    ko: "스테이킹 취소"
  },
  claim_rewards: {
    en: "Claim Rewards",
    zh: "提取奖励",
    id: "Tarik hadiah",
    ko: "보상 요청"
  },
  exit_claim_and_unstake: {
    en: "Exit: Claim and Unstake",
    zh: "退出：提取奖励并取消代币抵押",
    id: "Penarikan: Tarik hadiah dan batalkan hipotek token",
    ko: "종료 : 클레임 및 언 스테이 킹"
  },
  output_current_week: {
    en: "Output this week",
    zh: "本周产出"
  },
  output_history: {
    en: "history Output",
    zh: "已产出"
  }
};